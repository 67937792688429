<template>
  <div>
    <div v-if="debug">//{{getPercent}}////////</div>
    <div ref="dataWater" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import echartsLiquidfill from "echarts-liquidfill";

export default {
  props: {
    percent: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      debug: 0,
      getPercent:this.percent,
    };
  },
  watch: {
    percent: {
      handler() {
        this.getPercent = this.percent;//要手动同步赋值。
        this.initWaterBall();
      },
      deep: true
      // immediate: true
    }
  },
  methods: {
    initWaterBall() {
      let self = this;
      let dataWater = echarts.init(self.$refs.dataWater);
      self.log(self.waterOption);

      let options = {
        series: [
          {
            type: "liquidFill", //类型
            radius: "80%",
            data: [0, 0, 0], //波浪填充度数组，每一项的值是0-1，有几个波浪就有几项
            color: ["#4D46A2", "#D398EB", "#7469F3"], //水波的颜色 对应的是data里面值
            itemStyle: {
              opacity: 0.8, //波浪的透明度
              shadowBlur: 10, //波浪的阴影范围
              // shadowColor: "#f60" //阴影颜色
            },
            label: {
              position: ["50%", "50%"],
              fontSize: 16,
              color: "#fff",
              fontWeight: "normal"
            },
            outline: {
              show: true, //是否显示轮廓 布尔值
              borderDistance: 0, //外部轮廓与图表的距离 数字
              itemStyle: {
                borderColor: "#1B4175", //边框的颜色
                borderWidth: 5, //边框的宽度
                shadowBlur: 5, //外部轮廓的阴影范围 一旦设置了内外都有阴影
                shadowColor: "#000" //外部轮廓的阴影颜色
              }
            },
            backgroundStyle: {
              color: "#1B4175", //图表的背景颜色
              borderWidth: "2", //图表的边框宽度
              borderColor: "#D398EB", //图表的边框颜色
              itemStyle: {
                shadowBlur: 100, //设置无用
                shadowColor: "#f60", //设置无用
                opacity: 1 //设置无用
              }
            }
            // shape: "circle", //水填充图的形状 circle默认圆形  rect圆角矩形  triangle三角形  diamond菱形  pin水滴状 arrow箭头状  还可以是svg的path
            // center: ["50%", "50%"], //图表相对于盒子的位置 第一个是水平的位置 第二个是垂直的值 默认是[50%,50%]是在水平和垂直方向居中 可以设置百分比 也可以设置具体值
            // amplitude: 3, //振幅 是波浪的震荡幅度 可以取具体的值 也可以是百分比 百分比下是按图标的直径来算
            // waveLength: "50%", //波的长度 可以是百分比也可以是具体的像素值  百分比下是相对于直径的 取得越大波浪的起伏越小
            // phase: 0, //波的相位弧度 默认情况下是自动
            // period: (value, index) => {
            //   //控制波的移动速度 可以是函数 也可以是数字 两个参数  value 是data数据里面的值 index 是data值的索引
            //   return index * 2000;
            // },
            // direction: "left", //波移动的速度 两个参数  left 从右往左 right 从左往右
            // waveAnimation: true, //控制波动画的开关  值是布尔值 false 是关闭动画 true 是开启动画 也是默认值
            // animationEasing: "linear", //初始动画
            // animationEasingUpdate: "quarticInOut", //数据更新的动画效果
            // animationDuration: 3000, //初始动画的时长，支持回调函数，可以通过每个数据返回不同的 delay 时间实现更绚丽的初始动画效果
            // animationDurationUpdate: 300 //数据更新动画的时长
          }
        ]
        // backgroundColor: 'rgba(255,0,0,0.1)'//容器背景颜色
      };

      if (self.getPercent && self.getPercent>=0) {
        if(self.getPercent>1)self.getPercent=self.getPercent/100;
        options.series[0].data = options.series[0].data.map((item,i)=>{
          return self.getPercent*((10-i)/10);
        })
        self.log(options.series[0].data);
      }
      dataWater.setOption(options);

      window.addEventListener("resize", function() {
        dataWater.resize();
      });
    }
  },
  mounted() {
    this.initWaterBall();
  }
};
</script>

<style lang='sass' scoped>
</style>
